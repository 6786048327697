import React from "react"
import PropTypes from "prop-types"
import { Hero } from "../components/StandartPage/index"
import { StaticImage } from "gatsby-plugin-image"

// Components
import { Link, graphql } from "gatsby"

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  const tagHeader = `${tag}`
  const tagDesc = `"${tag}" kelimesiyle alakalı ${totalCount} gönderi
  bulundu.`

  return (
    <div>
      <Hero title={tagHeader} subtitle={tagDesc} />
      <div className="section media-featured">
        <div className="wrap-wide">
          <div className="flex-row">
            {edges.map(({ node }) => {
              const { slug, title, date } = node.frontmatter
              return (
                <Link key={slug} className="media-card" to={slug}>
                  <div className="flex-col">
                    <h4> {title}</h4>
                    <div className="card-info">
                      <span> {title}</span>
                      <span> {date}</span>
                    </div>
                  </div>
                  <div className="flex-col">
                    <div className="media-logo">
                      <StaticImage
                        className="img"
                        src="../images/image.png"
                        alt="Bilişim Avukatı, Sosyal Medya Avukatı, Fenomen Avukatı, İnternet Avukatı, Teknoloji Avukatı"
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                      />
                      <div className="btn black">Devamını Oku</div>
                    </div>
                  </div>
                  {title}
                </Link>
              )
            })}
          </div>
          <div className="section">
            <Link className="btn nobg" to="/tags">
              Tüm Etiketler
            </Link>
          </div>
        </div>
      </div>
      {/*
              This links to a page that does not yet exist.
              You'll come back to it!
            */}
    </div>
  )
}

Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Tags

export const pageQuery = graphql`
  query ($tag: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            slug
            date
          }
        }
      }
    }
  }
`
